/* src/components/organisms/MenuItemPastas/MenuItemPastas.css */

.MuiListItem-root {
  display: flex;
  align-items: center;
}

.MuiListItemText-root {
  margin-left: 8px; /* Ajuste de margem para ícones */
}

.menu-item-pastas-pasta .MuiSvgIcon-root {
  color: #1976d2; /* Cor do ícone de pasta */
}

.menu-item-pastas-pagina .MuiSvgIcon-root {
  color: #23b310; /* Cor do ícone de arquivo */
}
