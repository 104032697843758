.PerguntaDigitado-card {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: var(--max-width-componente);
  display: flex;
  justify-content: center;
}

.PerguntaDigitado-question-text {
  margin-bottom: 20px;
}

.PerguntaDigitado-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PerguntaDigitado-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.PerguntaDigitado-correct-input {
  border-color: green;
}

.PerguntaDigitado-incorrect-input {
  border-color: red;
}

.PerguntaDigitado-correct-icon {
  color: green;
  margin-left: 10px;
}

.PerguntaDigitado-incorrect-icon {
  color: red;
  margin-left: 10px;
}

.PerguntaDigitado-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.PerguntaDigitado-button-group {
  display: flex;
  gap: 10px;
}

.PerguntaDigitado-icon-group {
  display: none;
  gap: 10px;
}

.PerguntaDigitado-resultado {
  font-size: 18px;
  font-weight: bold;
}

.PerguntaDigitado-resposta-correta {
  font-size: 18px;
  color: #28a745;
  font-weight: bold;
  margin-top: 10px;
}

.PerguntaDigitado-comment-section {
  margin-top: 20px;
  padding: 10px;
  border-left: 4px solid #ccc;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {

  .PerguntaDigitado-card {
    margin: 0px;
    padding: 0px;
  }

  .PerguntaDigitado-button-group {
    display: none;
  }

  .PerguntaDigitado-icon-group {
    display: flex;
  }
}
