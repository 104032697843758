.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    padding: 20px;
  }
  
  .homepage h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .homepage p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    text-align: justify;
  }

  .homepage ul li {
      text-align: justify;
  }
  