.TreinoFlashCardsEditor-card {
  margin: 1rem 0;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.TreinoFlashCardsEditor-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.TreinoFlashCardsEditor-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

.editor-field {
  margin-bottom: 1.5rem;
}

.editor-field .MuiFormControlLabel-root {
  align-self: flex-end;
}
