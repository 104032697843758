@font-face {
  font-family: 'Virgil';
  src: url('./fonts/Virgil.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cascadia';
  src: url('./fonts/Cascadia.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  font-family: "Montserrat Alternates", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  color: white !important; /* Mantemos o texto branco para contraste */
  margin: 0.5em 0;
  padding: 0.5em;
  line-height: 1.2;
  border-radius: 8px;
  text-align: left;
}

/* Gradiente mais escuro para h1 */
h1 {
  background: linear-gradient(135deg, #010640 0%, #383c66 100%);
  font-size: 2.5em;
}

/* Um pouco mais claro para h2 */
h2 {
  background: linear-gradient(135deg, #00297a 0%, #4281ff 100%);
  font-size: 2em;
}

/* Continuando a clarear para h3 */
h3 {
  background: linear-gradient(135deg, #0b5cfe 0%, #8ab1ff 100%);
  font-size: 1.75em;
}

/* Gradiente mais claro para h4 */
h4 {
  background: linear-gradient(135deg, #288cc6 0%, #08476b 100%);
  font-size: 1.5em;
}

/* Tons ainda mais suaves para h5 */
h5 {
  background: linear-gradient(135deg, #c000d2 0%, #e033ff 100%);
  font-size: 1.25em;
}

/* Quase branco para h6 */
h6 {
  background: linear-gradient(135deg, #f82aea 0%, #ff44c7 100%);
  font-size: 1em;
  font-weight: 600;
}


.comentarios-verde {
  background-color: rgb(104, 227, 159);
}

.comentarios-azul {
  width: 100%;
  background-color: rgb(104, 206, 227);
}

.comentarios-amarelo {
  width: 100%;
  background-color: rgb(239, 251, 184);
}

.comentarios-vermelho {
  width: 100%;
  background-color: rgb(253, 171, 171);
}

.comentarios-laranja {
  width: 100%;
  background-color: rgb(255, 192, 119);
}

.comentarios-lilas {
  width: 100%;
  background-color: rgb(227, 171, 253);
}

.comentarios-rosa {
  width: 100%;
  background-color: rgb(255, 133, 214);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.menu-visible .content {
  margin-left: 250px;
}

.menu-hidden .content {
  margin-left: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding-top: 60px; /* Espaço para o Header */
}

.menu {
  width: 250px;
  transition: transform 0.3s, width 0.3s;
}

.menu-hidden .menu {
  transform: translateX(-250px);
  width: 0;
}

p {
  font-size: 16pt !important;
}

/* Media query para celulares */
@media (max-width: 600px) {
  h1 {
    font-size: 18pt;
  }

  h2 {
    font-size: 17pt;
  }

  h3 {
    font-size: 16pt;
  }

  h4 {
    font-size: 15pt;
  }

  h5 {
    font-size: 14pt;
  }

  h6 {
    font-size: 13pt;
  }
  p {
    font-size: 13pt !important;
  }

}