.flip-card {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    perspective: 1000px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }
  
  .flip-card.flipped .flip-card-front {
    transform: rotateY(180deg);
  }
  
  .flip-card.flipped .flip-card-back {
    transform: rotateY(0);
  }
  
  .flip-card-front,
  .flip-card-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  .flip-card-front {
    background: #fff;
    color: #333;
    transform: rotateY(0);
    transition: transform 0.6s;
  }
  
  .flip-card-back {
    background: #007bff;
    color: #fff;
    transform: rotateY(-180deg);
    transition: transform 0.6s;
  }
  