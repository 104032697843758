.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-title {
    margin-bottom: 20px;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
  }
  
  .login-button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  