.FlashCardTreino-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: center;
  width: 800px;
  margin: auto;
}

.FlashCardTreino-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
}

.FlashCardTreino-front, .FlashCardTreino-back {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.FlashCardTreino-divider {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ccc;
}

.FlashCardTreino-back.reveal {
  animation: revealAnimation 0.5s ease-in-out;
}

@keyframes revealAnimation {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}

.FlashCardTreino-buttons {
  margin-top: 16px;
}

.FlashCardTreino-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}

.FlashCardTreino-buttons button:hover {
  background-color: #0056b3;
}

.FlashCardTreino-comment {
  background-color: #fffbcc;
  border-left: 5px solid #ffd700;
  padding: 10px;
  border-radius: 4px;
  margin-top: 8px;
}
