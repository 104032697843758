/* Estilo principal para a estrutura da página */
.RevisaoEspacadaEdicaoPage-container {
    display: flex;
    height: 100%;
    background-color: #f9f9f9; /* Fundo claro para destaque */
    padding: 10px;
    box-sizing: border-box;
  }
  
  /* Estilo para a coluna de baralhos */
  .RevisaoEspacadaEdicaoPage-baralhos {
    width: 30%;
    padding: 15px;
    border-right: 1px solid #ddd; /* Separação suave entre colunas */
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
  }
  
  /* Estilo para a coluna de componentes */
  .RevisaoEspacadaEdicaoPage-componentes {
    width: 70%;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
  }
  
  /* Estilo para os nomes dos baralhos na árvore */
  .RevisaoEspacadaEdicaoPage-baralhoNome {
    cursor: pointer;
    padding-left: 0.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .RevisaoEspacadaEdicaoPage-baralhoNome:hover {
    color: #007bff; /* Cor de destaque ao passar o mouse */
  }
  
  /* Estilo para o botão de ação (menu de contexto) */
  .RevisaoEspacadaEdicaoPage-actionButton {
    padding: 5px;
    color: #555;
  }
  
  .RevisaoEspacadaEdicaoPage-actionButton:hover {
    color: #007bff; /* Cor de destaque ao passar o mouse */
  }
  
  /* Estilo para a lista de componentes */
  .RevisaoEspacadaEdicaoPage-componentes ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .RevisaoEspacadaEdicaoPage-componentes ul tr {
    border-width: 3px !important;
    border-style: solid !important;
    border-color: #218838 !important;
  }
  
  .RevisaoEspacadaEdicaoPage-componentes li {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f4f4f4;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilo para o botão de adicionar componente */
  .RevisaoEspacadaEdicaoPage-componentes button {
    margin-top: 20px;
    background-color: #28a745;
    color: white;
  }
  
  .RevisaoEspacadaEdicaoPage-componentes button:hover {
    background-color: #218838; /* Cor mais escura ao passar o mouse */
  }

  .RevisaoEspacadaEdicaoPage-ListaComponentes tr {
    width: 5px;
    margin: 5px;
    border: 5px solid blue !important;
  }
  
  .p-multiselect-panel {
    background-color: #fff !important; /* Cor de fundo sólida */
    opacity: 1 !important; /* Garantir que o fundo seja opaco */
    border-color: #007bff;
    border-style: solid;
    border-width: 1px;
  }

  .p-multiselect-item {
      background-color: #fff; /* Também pode customizar o fundo de cada item */
  }

  .p-multiselect-item .p-checkbox {
    position: absolute;
    right: 10px; /* Ajuste a posição à direita */
    top: 50%;
    transform: translateY(-50%);
}

/* Esconde o ícone SVG de checkmark adicional */
.p-multiselect-item .p-checkbox-box {
  display: none;
}

.RevisaoEspacadaEdicaoPage-container .selected-baralho {
  font-weight: bold;
  color: #007bff; /* Cor de destaque, por exemplo azul */
  background-color: #f0f8ff; /* Cor de fundo clara para destacar o item */
}
