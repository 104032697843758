.comentario-container {
    margin: 0 0 10px 0 !important;
    padding: 0 15px 0 10px !important;
}

.comentario-container p {
    margin: 0 !important;
}

.comentario-container-div {

}

.MuiAccordionSummary-content {
    margin: 0 !important;
}

.MuiAccordionSummary-root {
    padding: 0 10px 0 0 !important;
}

.Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionDetails-root p {
    margin: 3px 0 !important;
}

.comentario-container-Alerta {
    background-color: #fff3cd !important;
}

.comentario-container-Questão {
    background-color: #fcfcf5 !important;
}

.comentario-container-Informação {
    background-color: #d4edda !important;
}

.comentario-container-Erro {
    background-color: #f8d7da !important;
}

.comentario-container-Importante {
    background-color: #fff8e1 !important;
}

.comentario-container-Jurisprudência {
    background-color: #dcedf7 !important;
}

.comentario-container-Lei {
    background-color: #ebeae8 !important;
}