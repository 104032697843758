.ordenacao-container {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: var(--max-width-componente);
  width: 100%;
  text-align: center;
  margin: 0;
  transition: all 0.3s ease;
}

.ordenacao-container:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.ordenacao-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.ordenacao-item {
  padding: 12px;
  margin: 6px 0;
  background: #fbfbfb;
  color: rgb(0, 0, 0);
  border-radius: 8px;
  border-width: 1px;
  cursor: grab;
  transition: all 0.3s ease;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-color: black;
  border-style: solid;
}

.ordenacao-item:hover {
  background: #a5c6ea;
}

.ordenacao-correct {
  background: #7dff9b !important;
}

.ordenacao-incorrect {
  background: #ff6f7d !important;
  color: rgb(0, 0, 0) !important;
}

.ordenacao-dragging {
  opacity: 0.5;
  background: #6c757d;
}

.ordenacao-placeholder {
  background: #f0f0f0;
  border: 2px dashed #ccc;
  height: 50px;
  margin: 5px 0;
}

.ordenacao-result {
  margin-top: 20px;
  font-size: 1.2em;
}

.ordenacao-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.ordenacao-button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ordenacao-button:hover {
  background: #0056b3;
}

.ordenacao-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.ordenacao-button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .ordenacao-container {
    margin: 0;
    padding: 0;
  }
}
