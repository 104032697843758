.excalidraw-editor-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  min-height: 600px; /* Garantir que o editor seja visível na tela padrão */
}

.excalidraw-canvas-container {
  flex: 1;
  height: 100%; /* Ocupa o espaço disponível na tela padrão */
  min-height: 500px; /* Altura mínima para garantir visibilidade */
  overflow: hidden;
  position: relative;
}

.excalidraw-editor-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.fullscreen .excalidraw-canvas-container {
  flex: 1;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 60px); /* Altura completa menos o rodapé */
}

.fullscreen .excalidraw-editor-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
