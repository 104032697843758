.PerguntaMultiplaEscolha-card {
  margin: 20px;
  padding: 20px;
  max-width: var(--max-width-componente);
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PerguntaMultiplaEscolha-question-text {
  width: 90%;
  margin-bottom: 20px;
}

.PerguntaMultiplaEscolha-option-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PerguntaMultiplaEscolha-option-container .MuiFormControlLabel-root {
  flex-grow: 1;
}

.PerguntaMultiplaEscolha-correct-option .MuiFormControlLabel-label {
  color: green;
}

.PerguntaMultiplaEscolha-incorrect-option .MuiFormControlLabel-label {
  color: red;
}

.PerguntaMultiplaEscolha-correct-icon {
  color: green;
  margin-left: 10px;
}

.PerguntaMultiplaEscolha-incorrect-icon {
  color: red;
  margin-left: 10px;
}

.PerguntaMultiplaEscolha-actions-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.PerguntaMultiplaEscolha-correct-answer-text {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

.PerguntaMultiplaEscolha-incorrect-answer-text {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.PerguntaMultiplaEscolha-comment-section {
  margin-top: 20px;
  padding: 10px;
  border-left: 4px solid #ccc;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .PerguntaMultiplaEscolha-card  {
    margin: 0;
    padding: 0;
  }

  .MuiCardContent-root {
    width: 95% !important;
    padding-right: 20px !important;
  }
}