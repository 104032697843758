.comentario-pergunta {
    margin: 0 !important;
    padding: 0 !important;
}

.comentario-resposta {
    margin: 0 !important;
    padding: 0 !important;
}

.Mui-expanded {
    margin: 0 !important;
}

.comentario-sumario {
    margin: 0;
    padding: 0;
}

.parser-customaccordion-Alerta {
    background-color: #fff3cd !important;
}

.parser-customaccordion-Questão {
    background-color: #fcfcf5 !important;
}

.parser-customaccordion-Informação {
    background-color: #d4edda !important;
}

.parser-customaccordion-Erro {
    background-color: #f8d7da !important;
}

.parser-customaccordion-Importante {
    background-color: #fff8e1 !important;
}

.parser-customaccordion-Jurisprudência {
    background-color: #dcedf7 !important;
}

.parser-customaccordion-Lei {
    background-color: #ebeae8 !important;
}