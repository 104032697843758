.CardEditor-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.CardEditor-section {
  margin-bottom: 20px;
}

.CardEditor-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
