.PerguntaDigitadoEditor-card {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PerguntaDigitadoEditor-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
