.treino-termo-definicao-editor {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .treino-termo-definicao-editor-term-definition {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .treino-termo-definicao-editor-term-definition .MuiTextField-root {
    margin: 10px 0;
  }
  
  .treino-termo-definicao-editor .MuiFormControlLabel-root {
    margin: 10px 0;
  }
  
  .treino-termo-definicao-editor .MuiSelect-root {
    margin: 10px 0;
  }
  
  .treino-termo-definicao-editor button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background 0.3s;
  }
  
  .treino-termo-definicao-editor button:hover {
    background: #0056b3;
  }
  