.RevisaoEspacadaViewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.RevisaoEspacadaPage-dialogContent {
  padding: 10px;
}

.RevisaoEspacadaViewer-content {
  display: flex;
  justify-content: center;
  max-width: var(840px);
  width: 100%;
  flex-grow: 1;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden; /* Evita o scroll horizontal */
  margin-bottom: 5px;
}

.RevisaoEspacadaViewer-content img, 
.RevisaoEspacadaViewer-content iframe, 
.RevisaoEspacadaViewer-content video {
    max-width: 100%;
    height: auto;
  }

.RevisaoEspacadaViewer-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 50%;
}

.RevisaoEspacadaViewer-buttons button {
    width: 50%;
    gap: 10px;
    font-size: 16px !important;
    text-transform: none !important;
    padding-top: 3px;
    padding-bottom: 3px;
}


@media (max-width: 768px) {
  .RevisaoEspacadaViewer-content {
    padding: 0;
    margin: 0;
  }

  .RevisaoEspacadaViewer-buttons {
    width: 100%;
    gap: 10px;
    font-size: 12px !important;
  }

  .RevisaoEspacadaViewer-buttons button {
    width: 100%;
    gap: 10px;
    font-size: 16px !important;
    text-transform: none !important;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .RevisaoEspacadaViewer-button {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    gap: 0px !important;
  }

  .intervalo-span {
    font-size: 12px;
  }
}
