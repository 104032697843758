.flashcard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: var(--max-width-componente);
    width: 100%;
  }
  
  .flashcard-question {
    background-color: #f5f5f5;
    color: #333;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .flashcard-answer {
    background-color: #e0e0e0;
    color: #333;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
  }
  
  .flashcard-more-info {
    background-color: #d0d0d0;
    color: #333;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
  }
  
  .flashcard-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .flashcard-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #2196F3;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .flashcard-button:hover {
    background-color: #1976D2;
  }
  
  .flashcard-button.easy {
    background-color: #4CAF50;
  }
  
  .flashcard-button.medium {
    background-color: #FF9800;
  }
  
  .flashcard-button.hard {
    background-color: #F44336;
  }
  
  .flashcard-button.wrong {
    background-color: #9E9E9E;
  }
  
  .flashcard-navigation {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .flashcard-show-answer,
  .flashcard-show-more {
    margin-top: 10px;
    cursor: pointer;
    color: #1976D2;
    font-weight: bold;
    background: none;
    border: none;
    padding: 0;
  }
  