.RevisaoEspacadaPage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background-color: #f7f9fc;
  color: #333;
}

.RevisaoEspacadaPage-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.RevisaoEspacadaPage-addBaralhoButton {
  align-self: flex-start;
  margin-bottom: 20px;
}

.RevisaoEspacadaPage-tabelaBaralhos {
  width: 100%;
  margin-bottom: 20px;
}

.RevisaoEspacadaPage-baralhoNomeColumn {
  display: flex;
  align-items: center;
  padding: 10px;
}

.RevisaoEspacadaPage-baralhoNome {
  font-weight: 600;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 8px; /* Espaço entre o ícone e o texto */
}

.RevisaoEspacadaPage-baralhoNome:hover {
  color: #0056b3;
}

.RevisaoEspacadaPage-columnCenter {
  text-align: center;
}

.RevisaoEspacadaPage-actionButton {
  color: #555;
}

.RevisaoEspacadaPage-noBaralhosMessage {
  font-size: 1.2rem;
  color: #999;
  margin-top: 20px;
}

.RevisaoEspacadaPage-dialogTitle {
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RevisaoEspacadaPage-dialogContent {
  padding: 20px;
}

.RevisaoEspacadaPage-dialogActions {
  padding: 10px 20px;
}

.RevisaoEspacadaPage-togglerButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 8px; /* Espaço entre a seta e o ícone de pasta */
}

.RevisaoEspacadaPage-menuItem {
  font-size: 1rem;
}

.RevisaoEspacadaPage-tabelaBaralhos .p-treetable-thead th {
  text-align: center;
  vertical-align: middle;
}

.RevisaoEspacadaPage-tabelaBaralhos .p-treetable-tfoot td {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.RevisaoEspacadaPage-tabelaBaralhos .p-treetable-tbody td {
  vertical-align: middle;
}

.RevisaoEspacadaPage-quantidadeNovos {
  color: #007bff; /* Azul para Novos */
  font-weight: bold;
}

.RevisaoEspacadaPage-quantidadeAprender {
  color: #dc3545; /* Vermelho para Aprender */
  font-weight: bold;
}

.RevisaoEspacadaPage-quantidadeRevisar {
  color: #28a745; /* Verde para A Revisar */
  font-weight: bold;
}

.RevisaoEspacadaPage-quantidadeNovos, .RevisaoEspacadaPage-totalNovos {
  color: #007bff; /* Azul para Novos */
  font-weight: bold;
}

.RevisaoEspacadaPage-quantidadeAprender, .RevisaoEspacadaPage-totalAprender {
  color: #dc3545; /* Vermelho para Aprender */
  font-weight: bold;
}

.RevisaoEspacadaPage-quantidadeRevisar, .RevisaoEspacadaPage-totalRevisar {
  color: #28a745; /* Verde para A Revisar */
  font-weight: bold;
}

.RevisaoEspacadaPage-footerRow {
  border-top: 2px solid #ddd !important; /* Borda separadora para os totais */
}

.RevisaoEspacadaPage-footerLabel {
  font-weight: bold;
  padding-left: 10px;
}

/* Layout padrão para telas maiores (desktops) permanece o mesmo */

/* Ajustes para tablets */
@media (max-width: 1024px) {
  .RevisaoEspacadaPage-container {
    padding: 10px; /* Reduzir o padding para economizar espaço */
  }

  .RevisaoEspacadaPage-header {
    font-size: 1.5rem; /* Reduzir o tamanho da fonte do cabeçalho */
    margin-bottom: 15px;
  }

  .RevisaoEspacadaPage-addBaralhoButton {
    font-size: 0.9rem; /* Ajuste de tamanho do botão */
    padding: 8px 12px;
    margin-bottom: 15px;
  }

  .RevisaoEspacadaPage-tabelaBaralhos {
    font-size: 0.9rem; /* Ajuste do tamanho da fonte na tabela */
  }

  .RevisaoEspacadaPage-baralhoNome {
    font-size: 0.9rem; /* Reduzir o tamanho do nome do baralho */
  }

  .RevisaoEspacadaPage-dialogTitle {
    font-size: 1.2rem; /* Ajustar o tamanho do título do diálogo */
    padding: 8px 15px;
  }

  .RevisaoEspacadaPage-dialogContent {
    padding: 15px; /* Reduzir padding interno dos diálogos */
  }

  .RevisaoEspacadaPage-dialogActions {
    padding: 8px 15px;
  }

  .RevisaoEspacadaPage-menuItem {
    font-size: 0.9rem; /* Ajustar tamanho da fonte no menu contextual */
  }

  .RevisaoEspacadaPage-footerLabel {
    padding-left: 5px; /* Reduzir o espaçamento no rodapé */
  }
}

/* Ajustes para smartphones */
@media (max-width: 768px) {
  .RevisaoEspacadaPage-container {
    padding: 5px; /* Reduzir ainda mais o padding para telas menores */
  }

  .RevisaoEspacadaPage-header {
    font-size: 1.2rem; /* Reduzir o tamanho da fonte do cabeçalho */
    margin-bottom: 10px;
  }

  .RevisaoEspacadaPage-addBaralhoButton {
    font-size: 0.8rem; /* Ajuste ainda menor do botão */
    padding: 6px 10px;
    margin-bottom: 10px;
    display: none !important;
  }

  .RevisaoEspacadaPage-tabelaBaralhos {
    font-size: 0.8rem; /* Ajuste da fonte na tabela */
  }

  .RevisaoEspacadaPage-baralhoNome {
    font-size: 0.8rem; /* Reduzir o tamanho do nome do baralho ainda mais */
  }

  .RevisaoEspacadaPage-dialogTitle {
    font-size: 1rem; /* Ajustar o tamanho do título do diálogo */
    padding: 5px 10px;
  }

  .RevisaoEspacadaPage-dialogContent {
    padding: 10px; /* Reduzir padding interno dos diálogos */
  }

  .RevisaoEspacadaPage-dialogActions {
    padding: 5px 10px;
  }

  .RevisaoEspacadaPage-menuItem {
    font-size: 0.8rem; /* Ajustar tamanho da fonte no menu contextual */
  }

  .RevisaoEspacadaPage-footerLabel {
    padding-left: 3px !important; /* Reduzir ainda mais o espaçamento no rodapé */
  }

  .RevisaoEspacadaPage-columnCenter, 
  .RevisaoEspacadaPage-quantidadeNovos, 
  .RevisaoEspacadaPage-quantidadeAprender, 
  .RevisaoEspacadaPage-quantidadeRevisar {
    font-size: 0.8rem !important; /* Ajustar tamanho da fonte das colunas */
  }

  .RevisaoEspacadaPage-dialogTitle,
  .RevisaoEspacadaPage-dialogContent,
  .RevisaoEspacadaPage-dialogActions {
    padding: 5px !important; /* Reduzir padding nos diálogos para celulares */
  }

  /* Ajustes para colunas se adaptarem ao conteúdo em dispositivos móveis */
.RevisaoEspacadaPage-tabelaBaralhos .p-treetable-tbody td {
  width: auto;
  max-width: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RevisaoEspacadaPage-tabelaBaralhos .p-treetable-thead th {
  width: auto;
  max-width: none;
  white-space: nowrap;
}

.RevisaoEspacadaPage-baralhoNomeColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RevisaoEspacadaPage-columnCenter {
  text-align: center;
  white-space: nowrap;
}

.TreeTable-Revisao-Acoes {
  text-align: center;
  white-space: nowrap;
}


  
}
