.PerguntaMultiplaEscolhaTreino-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 800px;
  margin: auto;
}

.PerguntaMultiplaEscolhaTreino-question {
  font-size: 18px;
  margin-bottom: 16px;
}

.PerguntaMultiplaEscolhaTreino-options {
  display: flex;
  flex-direction: column;
}

.PerguntaMultiplaEscolhaTreino-option {
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.PerguntaMultiplaEscolhaTreino-option.correct {
  background-color: rgba(0, 255, 0, 0.3);
}

.PerguntaMultiplaEscolhaTreino-option.wrong {
  background-color: rgba(255, 0, 0, 0.3);
}

.PerguntaMultiplaEscolhaTreino-option.selected {
  background-color: rgba(173, 216, 230, 0.3);
}

.PerguntaMultiplaEscolhaTreino-buttons {
  margin-top: 20px;
}

.PerguntaMultiplaEscolhaTreino-buttons button {
  padding: 10px 20px;
  margin-right: 10px;
}

.PerguntaMultiplaEscolhaTreino-comment {
  background-color: #fffbcc;
  border-left: 5px solid #ffd700;
  padding: 10px;
  border-radius: 4px;
  margin-top: 8px;
}

.correct-mark {
  color: green;
  margin-left: 10px;
}

.wrong-mark {
  color: red;
  margin-left: 10px;
}
