.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    background-color: #4caf50;
    color: white;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: fadeInOut 3s ease-in-out forwards;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  