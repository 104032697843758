.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1007;
}

.botao-alterar-email {
  display: none !important;
}

.header-botoes-esquerda {
  display: flex;
  align-items: center;
}

.menu-toggle-button,
.home-button,
.admin-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.section-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-button {
  background: none;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.section-button:hover {
  background-color: #464545;
}

.section-button.active {
  background-color: #555;
  border-bottom: solid 3px white;
  border-radius: 5%;
}

.section-button svg {
  margin-right: 8px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.subsection-tabs {
  display: flex;
  flex-direction: row;
  position: absolute;
  background-color: #333;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  top: 50px; /* Ajusta a posição das abas */
  left: 0;
  z-index: 1;
}

.icones-secoes {
  font-size: 24px;
}

@media (max-width: 768px) {
  .section-button span {
    display: none;
  }
}

.user-menu-container {
  position: relative;
  display: inline-block;
}

.user-menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  margin-right: 25px;
}

.user-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #333;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

.user-menu button {
  background: none;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
}

.user-menu button:hover {
  background-color: #464545;
}

.header-right {
  display: flex;
  align-items: center;
}
