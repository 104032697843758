.TreinoFlashCard-card {
  margin: 0;
  padding: 10px;
  max-width: var(--max-width-componente);
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.TreinoFlashCard-question-face {
  background-color: #f9f9f9;
  width: 100%;
}

.TreinoFlashCard-answer-face {
  background-color: #e9f7ef;
  width: 100%;
}

.TreinoFlashCard-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.TreinoFlashCard-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  width: 100%;
}

.TreinoFlashCard-question, .TreinoFlashCard-answer, .TreinoFlashCard-comment {
  margin-bottom: 1rem;
  width: 100%;
}

.TreinoFlashCard-question {
  font-size: 1.25rem;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.TreinoFlashCard-answer {
  font-size: 1.25rem;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.TreinoFlashCard-response-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.TreinoFlashCard-comment {
  font-size: 1rem;
  padding: 0.75rem;
  background: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
  text-align: center;
  width: 100%;
}

.TreinoFlashCard-comment a {
  text-decoration: none;
}

.TreinoFlashCard-comment a:hover {
  text-decoration: underline;
}

.MuiCardContent-root {
  width: 100%;
}

@media (max-width: 768px) {
  .TreinoFlashCard-card  {
    margin: 0;
    padding: 0;
  }

  
  .TreinoFlashCard-main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;
    text-align: center;
    padding: 0;
    cursor: pointer;
    width: 100%;
  }
  
  .TreinoFlashCard-question, .TreinoFlashCard-answer, .TreinoFlashCard-comment {
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .TreinoFlashCard-question {
    font-size: 1.25rem;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .TreinoFlashCard-answer {
    font-size: 1.25rem;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .TreinoFlashCard-response-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .TreinoFlashCard-comment {
    font-size: 1rem;
    padding: 0.75rem;
    background: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #333;
    text-align: center;
    width: 100%;
  }
  
  .TreinoFlashCard-comment a {
    text-decoration: none;
  }
  
  .TreinoFlashCard-comment a:hover {
    text-decoration: underline;
  }
  
  .MuiCardContent-root {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
}
