/* TextoEditor.css */
.TextoEditor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.TextoEditor-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 1000;
}

.TextoEditor-editor {
  width: 80%; /* Aumenta a largura da área do editor */
  min-height: 400px; /* Define uma altura mínima */
  flex-grow: 1;
}

.TextoEditor-container.fullscreen .TextoEditor-editor {
  width: 95%;
  height: calc(100vh - 120px); /* Deixa um espaço acima do botão */
}

.TextoEditor-fullscreenButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
}

.TextoEditor-buttons {
  display: flex;
  justify-content: center; /* Centraliza os botões */
  gap: 20px; /* Adiciona um espaço entre os botões */
  width: 100%;
  margin-top: 10px;
}
