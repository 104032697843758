.timeline-game {
    padding: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.timeline-phases-container {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
}

.timeline-phase-column {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;
    position: relative;
    padding: 0 0 0 25px;
    width: auto; /* Define largura automática */
}

.timeline-phase-column li {
    position: relative;
    background: #92c7ff;
    color: rgb(0, 0, 0);
    padding: 10px 10px;
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    width: auto; /* Define largura automática */
}

/* Remova os triângulos */
.timeline-phase-column li::before,
.timeline-phase-column li::after {
    content: none;
}

/* Adiciona uma seta entre as fases */
.timeline-phase-column:not(:last-child)::after {
    content: '➩';
    font-size: 24px;
    color: #000;
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
}

.timeline-dragging {
    opacity: 0.5;
}

.timeline-correct {
    background: #94fcab !important;
}

.timeline-incorrect {
    background: #f49898 !important;
}

.timeline-partial {
    background: #fcf68b !important;
}

.timeline-result {
    margin-top: 20px;
    font-size: 1.2em;
}

.timeline-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0e2b4a;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.timeline-button:hover {
    background-color: #0056b3;
}

.timeline-item-input {
    width: auto;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    white-space: pre-line;
    min-height: 100px;
    outline: none;
    background-color: white; /* Ensure item background is white */
}

.timeline-item-input.blue {
    color: blue ;
}

.timeline-item-input span[style*="color:green"] {
    color: green !important;
}

.timeline-item-input span[style*="color:red"] {
    color: red !important;
    text-decoration: line-through !important;
}

.timeline-item-input span[style*="color:orange"] {
    color: orange !important;
}

.timeline-fundobranco {
    background: white !important;
}

.timeline-item-label {
    display: inline-block;
    margin: 2px;
    padding: 2px 5px;
    background: #f1f1f1;
    border-radius: 3px;
    border: 1px solid #050202;
}
