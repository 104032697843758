.PerguntaMultiplaEscolhaEditor-card {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PerguntaMultiplaEscolhaEditor-question-type-control {
  margin-top: 20px;
  margin-bottom: 20px;
}

.PerguntaMultiplaEscolhaEditor-option-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PerguntaMultiplaEscolhaEditor-option-container .MuiFormControlLabel-root {
  flex-grow: 1;
}

.PerguntaMultiplaEscolhaEditor-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
