.PerguntaDigitadaTreino-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: center;
  width: 800px;
  margin: auto;
}

.PerguntaDigitadaTreino-question {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.PerguntaDigitadaTreino-result.correct {
  color: green;
}

.PerguntaDigitadaTreino-result.incorrect {
  color: red;
}

.PerguntaDigitadaTreino-buttons {
  margin-top: 20px;
}

.PerguntaDigitadaTreino-buttons button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}

.PerguntaDigitadaTreino-buttons button:hover {
  background-color: #f0f0f0;
}

.PerguntaDigitadaTreino-comment {
  background-color: #fffbcc;
  border-left: 5px solid #ffd700;
  padding: 10px;
  border-radius: 4px;
  margin-top: 8px;
}
