.quiz-multiplo-imediato-container {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.quiz-multiplo-imediato-header {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.quiz-multiplo-imediato-question {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.quiz-multiplo-imediato-options {
    display: flex;
    flex-direction: column;
}

.quiz-multiplo-imediato-option {
    background: #007bff;
    border: none;
    color: white;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-multiplo-imediato-option:hover {
    background: #0056b3;
}

.quiz-multiplo-imediato-option.selected {
    background: #0056b3;
}

.quiz-multiplo-imediato-correct {
    color: green;
}

.quiz-multiplo-imediato-incorrect {
    color: red;
}

.quiz-multiplo-imediato-navigation {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.quiz-multiplo-imediato-nav-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.quiz-multiplo-imediato-response h3 {
    color: green;
}

.quiz-multiplo-imediato-response p {
    color: black;
}

.quiz-multiplo-imediato-result {
    font-size: 1.5em;
    margin-top: 20px;
}

.quiz-multiplo-imediato-reset-button {
    background: #ff5722;
    border: none;
    color: white;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-multiplo-imediato-reset-button:hover {
    background: #e64a19;
}
