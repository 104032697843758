.pergunta-rapida {
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 90%;
}

.pergunta-rapida-header {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.pergunta-rapida-icon {
  margin-right: 12px;
  font-size: 24px;
  color: white;
}

.pergunta-rapida-question {
  flex-grow: 1;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.pergunta-rapida-answer {
  background-color: #f8f9fa;
  font-size: 16px;
  color: #333;
}

.icon.question {
  color: #17a2b8; /* Teal */
}

.icon.information {
  color: #007bff; /* Blue */
}

.icon.warning {
  color: #ffffff; /* Yellow */
}

.icon.error {
  color: #dc3545; /* Red */
}

.pergunta-rapida.question .MuiAccordionSummary-root {
  background-color: #17a2b8; /* Teal */
}

.pergunta-rapida.information .MuiAccordionSummary-root {
  background-color: #007bff; /* Blue */
}

.pergunta-rapida.warning .MuiAccordionSummary-root {
  background-color: #ffc107; /* Yellow */
}

.pergunta-rapida.error .MuiAccordionSummary-root {
  background-color: #dc3545; /* Red */
}

.MuiAccordionSummary-expandIconWrapper {
  color: white;
}

.MuiAccordion-root {
  border-radius: 8px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.MuiAccordion-root:before {
  display: none;
}
