/* src/components/QuizMultiplaEscolha/QuizMultiplaEscolha.css */

.QuizMultiplaEscolha-container {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.QuizMultiplaEscolha-header {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.QuizMultiplaEscolha-question {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #555;
}

.QuizMultiplaEscolha-options {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    text-align: left;
}

.QuizMultiplaEscolha-options li {
    margin-bottom: 10px;
}

.QuizMultiplaEscolha-navigation {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.QuizMultiplaEscolha-navigation button {
    background: #28a745;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.QuizMultiplaEscolha-navigation button:hover {
    background: #218838;
}

.QuizMultiplaEscolha-results {
    text-align: left;
    margin-top: 20px;
}

.QuizMultiplaEscolha-result-item {
    margin-bottom: 20px;
}

.QuizMultiplaEscolha-correct {
    color: green;
}

.QuizMultiplaEscolha-incorrect {
    color: red;
}

.QuizMultiplaEscolha-button {
    margin-top: 20px;
    background: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.QuizMultiplaEscolha-button:hover {
    background: #0056b3;
}
