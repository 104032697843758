.itens-selecao-container {
    margin: 0px;
    max-width: var(--max-width-componente);
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .itens-selecao-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .itens-selecao-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
  }
  
  .itens-selecao-item.selected {
    background-color: lightblue;
  }
  
  .itens-selecao-item.correct {
    background-color: lightgreen;
  }
  
  .itens-selecao-item.incorrect {
    background-color: lightcoral;
  }
  
  .itens-selecao-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
  
  .itens-selecao-button {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-weight: 700;
    background-color: #007bff;
    font-size: 12pt;
  }
  
  .itens-selecao-button:hover {
    background-color: #0056b3;
  }
  
  .accordion {
    cursor: pointer;
    margin-top: 10px;
  }
  
  .itens-selecao-comment {
    margin-top: 10px;
  }
  