.preencher-quadro-editor {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.preencher-quadro-editor-header {
  margin-bottom: 20px;
}

.preencher-quadro-editor-header input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.preencher-quadro-editor-body {
  display: flex;
  flex-direction: column;
}

.preencher-quadro-editor-add {
  display: flex;
  margin-bottom: 10px;
}

.preencher-quadro-editor-add input {
  flex: 1;
  padding: 8px;
  font-size: 16px;
}

.preencher-quadro-editor-add button {
  margin-left: 10px;
  padding: 8px 16px;
  font-size: 16px;
}

.preencher-quadro-editor-table {
  width: 100%;
  border-collapse: collapse;
}

.preencher-quadro-editor-table th,
.preencher-quadro-editor-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.preencher-quadro-editor-table textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.preencher-quadro-editor-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.preencher-quadro-editor-footer button {
  padding: 8px 16px;
  font-size: 16px;
}
