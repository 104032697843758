.ImageOcclusionEditor-container {
  position: relative;
  margin: 0 auto;
  border: 1px solid #ccc;
  overflow: hidden;
}

.ImageOcclusionEditor-image {
  display: block;
}

.ImageOcclusionEditor-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}

.ImageOcclusionEditor-occlusion {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
}

.ImageOcclusionEditor-input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  font-weight: bold;
  text-align: center;
}
