/* src/components/QuizDigitado/QuizDigitado.css */

.QuizDigitado-quiz-container {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.QuizDigitado-quiz-header {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.QuizDigitado-quiz-question {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.QuizDigitado-quiz-navigation {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.QuizDigitado-quiz-navigation button {
    background: #28a745;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.QuizDigitado-quiz-navigation button:hover {
    background: #218838;
}

.QuizDigitado-quiz-results {
    text-align: left;
    margin-top: 20px;
}

.QuizDigitado-quiz-result-item {
    margin-bottom: 20px;
}

.QuizDigitado-correct {
    color: green;
}

.QuizDigitado-incorrect {
    color: red;
}

.QuizDigitado-button {
    margin-top: 20px;
    background: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.QuizDigitado-button:hover {
    background: #0056b3;
}
