.summary-item {
  display: flex;
  align-items: center; /* Alinha o botão e o link verticalmente no centro */
  color: black !important;
  
}

.summary-button-container {
  flex: 0 0 30px; /* Define o mesmo tamanho para o botão ou espaço reservado */
  display: flex;
  justify-content: center;
}

.placeholder-button {
  display: inline-block;
  width: 30px; /* Mesmo tamanho do botão */
  visibility: hidden; /* Invisível, mas mantém o espaço reservado */
}

.summary-link-container {
  flex: 1;
  overflow-wrap: break-word;
  border-bottom: 1px rgb(203, 203, 203) solid;
  box-shadow: 0px 0px 6px 0px rgb(183, 189, 255);
}

.summary-item a {
  text-decoration: none;
  color: rgb(61, 116, 205) !important;
}
